<template>
  <div class="style-inputs">
      <div class="style-inputs-field">
          <h2>כותרת</h2>
           <el-input v-model="current_element.header.content" placeholder="כותרת" />
      </div>
      <div class="style-inputs-field">
          <h2>מיקום כותרת</h2>
          <div class="headers_options">
               <el-button @click="current_element.header.header_position='right'" type="primary">ימין</el-button>
               <el-button @click="current_element.header.header_position='center'" type="primary">מרכז</el-button>
               <el-button @click="current_element.header.header_position='left'" type="primary">שמאל</el-button>
          </div>
      </div>
      <div class="style-inputs-field">
          <h2>עיצוב כותרת</h2>
          <div class="headers_options">
               <el-button size="small" @click="current_element.header.header_bold=='unset'?current_element.header.header_bold='bold':current_element.header.header_bold='unset'" round type="danger">
                   <i class="material-icons">format_bold</i>
               </el-button>
               <el-button size="small" @click="current_element.header.header_decoration=='none'?current_element.header.header_decoration='underline':current_element.header.header_decoration='none'" round type="success">
                    <i class="material-icons">format_underlined</i>
               </el-button>
               <el-button size="small" @click="current_element.header.header_style=='normal'?current_element.header.header_style='italic':current_element.header.header_style='normal'" round type="warning">
                   <i class="material-icons">format_italic</i>
               </el-button>
          </div>
      </div>
      <div class="style-inputs-field-rows">
          <div class="col-3">
            <h2>גודל כותרת</h2>
            <el-input-number style="width:100%" v-model="current_element.header.header_size" :min="1" :max="100" />
          </div>
          <div class="col-3">
            <h2>צבע כותרת</h2>
            <el-input type="color" v-model="current_element.header.header_color" />
          </div>
          <div class="col-3">
            <h2>שדה חובה?</h2>
             <el-switch
                    v-model="current_element.mandatory"
                    class="ml-2"
                    active-color="#13ce66"
                    inactive-color="#ff4949"
            />
          </div>
      </div>
      <div class="style-inputs-field">
          <h2>כותרת בתוך השדה</h2>
           <el-input v-model="current_element.placeholder" placeholder="כותרת" />
      </div>
      <div class="style-inputs-field">
          <h2>רוחב השדה</h2>
          <el-input-number style="width:100%;" v-model="current_element.width" :min="10" :max="100" />
      </div>
      <div v-if="current_element.type=='textarea'" class="style-inputs-field">
          <h2>גובה השדה</h2>
          <el-input-number style="width:100%;" v-model="current_element.rows" :min="2" :max="50" />
      </div>
      <div v-if="current_element.type=='number'" class="style-inputs-field-rows">
          <div class="col-2">
            <h2>מספר מינימלי</h2>
            <el-input-number style="width:100%;" v-model="current_element.min" :min="1" />
          </div>
          <div class="col-2">
            <h2>מספר מקסימלי</h2>
            <el-input-number style="width:100%;" v-model="current_element.max" :min="1" />
          </div>
      </div>
      <div class="style-inputs-field">
          <h2>מיקום השדה</h2>
          <div class="headers_options">
               <el-button @click="current_element.position='right'" type="primary">ימין</el-button>
               <el-button @click="current_element.position='center'" type="primary">מרכז</el-button>
               <el-button @click="current_element.position='left'" type="primary">שמאל</el-button>
          </div>
      </div>
      <div v-if="current_element.type=='select' || current_element.type=='multi_select'" class="style-inputs-field">
        <h2>ערכים לבחירה</h2>
        <el-select
            style="width:100%;"
            v-model="current_element.options"
            multiple
            filterable
            allow-create
            default-first-option
            :reserve-keyword="false"
            placeholder="הכנסת ערכים על ידי מקש האנטר"
        >
            <el-option
                v-for="option in current_element.options"
                :key="option"
                :value="option"
            />
        </el-select>
      </div>
  </div>
</template>

<script>
import {current_element} from '../../../../../../../Scripts/Forms_builder'
export default {
    setup(){

        return{current_element}
    }
}
</script>

<style scoped>
    .style-inputs{
        width: 100%;
        height: 100%;
        padding: 5px;
    }
    .style-inputs-field{
        margin-bottom: 5px;
        width: 100%;
        height: fit-content;
    }
    .style-inputs-field-rows{
        margin-bottom: 5px;
        width: 100%;
        height: fit-content;
        display: flex;
        justify-content: space-between;
    }
    .col-3{
        width: 30%;
        height: fit-content;
        flex-shrink: 0;
    }
    .col-2{
        width: 49%;
        height: fit-content;
         flex-shrink: 0;
    }
    .headers_options{
        width: 100%;
        height: fit-content;
        display: flex;
        justify-content: space-between;
    }
</style>