<template>
  <div class="manage-positions">
      <div class="manage-positions-tools">
          <el-input style="width:30%;" v-model="search" placeholder="חיפוש" />
      </div>
      <div class="manage-positions-content">
          <table>
            <tr>
                <th style="cursor:pointer;" @click="handleSortTable('קוד תפקיד', !selectedSorted.order)">קוד תפקיד</th>
                <th style="cursor:pointer;" @click="handleSortTable('נוצר בתאריך', !selectedSorted.order)">נוצר בתאריך</th>
                <th style="cursor:pointer;" @click="handleSortTable('שם תפקיד', !selectedSorted.order)">שם תפקיד</th>
                <th style="cursor:pointer;" @click="handleSortTable('תיאור תפקיד', !selectedSorted.order)">תיאור תפקיד</th>
                <th style="cursor:pointer;" @click="handleSortTable('מכסה', !selectedSorted.order)">מכסת בקשות</th>
                <th style="cursor:pointer;" @click="handleSortTable('בקשות', !selectedSorted.order)">מס בקשות</th>
                <th style="cursor:pointer;" @click="handleSortTable('פעילות', !selectedSorted.order)">פעילות</th>
                <th>פעולות</th>
            </tr>
            <template v-for="position in sorted_records" :key="position.uid">
                <tr>
                    <td>{{position.uid}}</td>
                    <td>{{new Date(position.created_at.seconds * 1000).toLocaleDateString('he')}}</td>
                    <td>{{position.name}}</td>
                    <td>{{position.description}}</td>
                    <td>{{position.max_apllications}}</td>
                    <td>{{position.requests.length}}</td>
                    <td v-if="position.active" style="color:green;">פעיל</td>
                    <td v-else style="color:red;">לא פעיל</td>
                    <td>
                        <i @click="handle_edit(position)" class="material-icons icon edit-icon">mode_edit</i>
                        <i @click="handle_delete(position)" class="material-icons icon delete-icon">delete</i>
                    </td>
                </tr>
            </template>
          </table>
      </div>
  </div>
</template>

<script>
import { ref } from '@vue/reactivity'
import { computed } from '@vue/runtime-core'
import {delete_position_from_db} from '../../../Scripts/Positions'
import {alert_confirm } from '../../../../../../Methods/Msgs'
export default {
    props:['positions'],
    emits:['edit','delete'],
    setup(props,{emit}){
        const search = ref('')
        const selectedSorted = ref({
            name: "",
            order: false
        });

        const handleSortTable = (name, order) => {
            selectedSorted.value.name = name;
            selectedSorted.value.order = order;
        };
        const sorted_records = ref(computed(()=>{
            if (selectedSorted.value.name == "") return filter_by_search.value
            if (selectedSorted.value.name == "נוצר בתאריך") {
                return filter_by_search.value.sort((a, b) => {
                    if (selectedSorted.value.order) {
                        return  new Date(a.created_at.seconds * 1000) > new Date(b.created_at.seconds * 1000)
                            ? 1
                            : new Date(b.created_at.seconds * 1000)  > new Date(a.created_at.seconds * 1000) 
                            ? -1
                            : 0;
                    }
                    return new Date(a.created_at.seconds * 1000)  > new Date(b.created_at.seconds * 1000) 
                        ? -1
                        : new Date(b.created_at.seconds * 1000)  > new Date(a.created_at.seconds * 1000) 
                        ? 1
                        : 0;
                })
            }
            if (selectedSorted.value.name == "קוד תפקיד") {
                return filter_by_search.value.sort((a, b) => {
                    if (selectedSorted.value.order) {
                        return  a.uid > b.uid
                            ? 1
                            : b.uid  > a.uid
                            ? -1
                            : 0;
                    }
                    return a.uid  > b.uid 
                        ? -1
                        : b.uid  > a.uid
                        ? 1
                        : 0;
                })
            }
            if (selectedSorted.value.name == "שם תפקיד") {
                return filter_by_search.value.sort((a, b) => {
                    if (selectedSorted.value.order) {
                        return  a.name > b.name
                            ? 1
                            : b.name  > a.name
                            ? -1
                            : 0;
                    }
                    return a.name  > b.name 
                        ? -1
                        : b.name  > a.name
                        ? 1
                        : 0;
                })
            }
            if (selectedSorted.value.name == "תיאור תפקיד") {
                return filter_by_search.value.sort((a, b) => {
                    if (selectedSorted.value.order) {
                        return  a.description > b.description
                            ? 1
                            : b.description  > a.description
                            ? -1
                            : 0;
                    }
                    return a.description  > b.description 
                        ? -1
                        : b.description  > a.description
                        ? 1
                        : 0;
                })
            }
            if (selectedSorted.value.name == "פעילות") {
                return filter_by_search.value.sort((a, b) => {
                    if (selectedSorted.value.order) {
                        return  a.active > b.active
                            ? 1
                            : b.active  > a.active
                            ? -1
                            : 0;
                    }
                    return a.active  > b.active 
                        ? -1
                        : b.active  > a.active
                        ? 1
                        : 0;
                })
            }
            if (selectedSorted.value.name == "מכסה") {
                return filter_by_search.value.sort((a, b) => {
                    if (selectedSorted.value.order) {
                        return  a.max_apllications > b.max_apllications
                            ? 1
                            : b.max_apllications  > a.max_apllications
                            ? -1
                            : 0;
                    }
                    return a.max_apllications  > b.max_apllications 
                        ? -1
                        : b.max_apllications  > a.max_apllications
                        ? 1
                        : 0;
                })
            }
            if (selectedSorted.value.name == "בקשות") {
                return filter_by_search.value.sort((a, b) => {
                    if (selectedSorted.value.order) {
                        return  a.requests.length > b.requests.length
                            ? 1
                            : b.requests.length  > a.requests.length
                            ? -1
                            : 0;
                    }
                    return a.requests.length  > b.requests.length 
                        ? -1
                        : b.requests.length  > a.requests.length
                        ? 1
                        : 0;
                })
            }
        }))

        const filter_by_search = ref(computed(()=>{
            if(search.value == '') return props.positions
            return props.positions.filter(pos=>{
                if(pos.uid == search.value) return pos
                if(new Date(pos.created_at.seconds * 1000).toLocaleDateString('he').includes(search.value)) return pos
                if(pos.name.includes(search.value)) return pos
                if(pos.description.includes(search.value)) return pos
            })
        }))
        
        const handle_edit=(position)=>{
            const selected_pos = JSON.parse(JSON.stringify(position))
            delete selected_pos.created_at
            emit('edit',selected_pos)
        }

        const handle_delete = (position)=>{
            alert_confirm(`למחוק את ${position.name}?`)
            .then(async(res)=>{
                if(res.isConfirmed){
                    await delete_position_from_db(position.uid)
                    emit('delete',position.uid)
                }
            })
        }

        return{
            search,
            filter_by_search,
            handle_edit,
            selectedSorted,
            handleSortTable,
            sorted_records,
            handle_delete
        }
    }
}
</script>

<style scoped>
    .manage-positions{
        width: 100%;
        height: 100%;
    }
    .manage-positions-tools{
        width: 100%;
        height: 50px;
        display: flex;
        align-items: center;
    }
    .manage-positions-content{
        width: 100%;
        height: calc(100% - 50px);
        overflow: hidden;
        overflow-y: auto;
    }
    table {
        border-collapse: collapse;
        width: 100%;
    }
    table td, table th {
        border: 1px solid #ddd;
        padding: 2px;
       
    }
    table tr:nth-child(even){background-color: #f2f2f2;}
    table tr:nth-child(odd){background-color: #e7e6e6;}
    table tr:hover {background-color: #ddd;}
    table td{
        text-align: center;
        color: var(--main);
        font-size: 16px;

    }
    table th {
        padding-top: 4px;
        padding-bottom: 4px;
        text-align: center;
        background-color: var(--secondary);
        color: white;
        position: sticky;
        top: 0;
    }

    .icon{
        cursor: pointer;
        margin: 0 5px;
    }
    .delete-icon{
        color: crimson;
    }
    .edit-icon{
        color: #f5953b;
    }


</style>