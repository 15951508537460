<template>
  <div class="builder-options">
    <div class="builder-options-header">
        <button @click="state='Elements'" class="builder-btn">אלמנטים</button>
        <button @click="state='StyleElement'" class="builder-btn">עיצוב</button>
        <button @click="handle_open_new_tab"  class="builder-btn preview-btn" v-if="elements_size>2">תצוגה</button>
        <button v-if="elements_size>2 && !edit_form" @click="handle_save_form_db" class="builder-btn save-btn">שמירה</button>
        <button v-if="edit_form" @click="handle_update_form" class="builder-btn update-btn">עדכון</button>
    </div>
    <div class="builder-options-content">
        <keep-alive>
            <component :is="state" />
        </keep-alive>
    </div>
  </div>
</template>

<script>
import { ref } from '@vue/reactivity'
import Elements from './Elements.vue'
import StyleElement from './StyleElement.vue'
import {elements,handle_save_form_db,edit_form,handle_update_form,temporary_save_data_form_in_db} from '../../../../../../Scripts/Forms_builder'
import { computed } from '@vue/runtime-core'
import router from '../../../../../../../../../router'
export default {
    components:{Elements,StyleElement},
    setup(){
        

        const handle_open_new_tab = async()=>{
            await temporary_save_data_form_in_db()
            let route = router.resolve({name:'PreviewForm'})
            window.open(route.href);
        }
        const elements_size = ref(computed(()=>{
            return elements.value.length
        }))
        
        const state = ref('Elements')
        return {
            handle_save_form_db,
            handle_update_form,
            handle_open_new_tab,
            state,
            elements_size,
            edit_form,
        }
    }
}
</script>

<style scoped>
    .builder-options{
        width: 100%;
        height: 100%;
    }
    .builder-options-header{
        width: 100%;
        height: 50px;
        display: flex;
        align-items: center;
        justify-content: space-evenly;
        padding: 0 5px;
        overflow-x: auto;
    }
    .builder-btn{
        width: 70px;
        height: 90%;
        border: 0;
        border-radius: 5px;
        background: #283046;
        color: #fff;
        font-size: 18px;
        cursor: pointer;
    }
 
    .builder-options-content{
        width: 100%;
        height: calc(100% - 50px);
        overflow:hidden;
        overflow-y: auto;
    }
    @media screen and (max-width: 1400px) {
        .builder-options-header{
            height: 100px;
            flex-wrap: wrap;
        }
        .builder-options-content{
            height: calc(100% - 100px);
        }
        .builder-btn{
            width: 48%;
            height: 40%;
        }
        
    }
    .save-btn{
        background: #28c76f;
    }
    .update-btn{
        background: #ffc107;
    }
    .preview-btn{
        background: #6610f2;
    }
</style>