<template>
  <div class="elements">
      <button @click="handle_add_element('h1','header','כותרת')" class="element-btn">כותרת</button>
      <button @click="handle_add_element('p','paragraph','פסקה')" class="element-btn">פסקה</button>
      <button @click="handle_add_element('el-input','text','שדה טקסט')" class="element-btn">שדה טקסט</button>
      <button @click="handle_add_element('el-input','textarea','שדה תוכן')" class="element-btn">שדה תוכן</button>
      <button @click="handle_add_element('el-input','number','שדה מספר')" class="element-btn">שדה מספר</button>
      <button @click="handle_add_element('el-input','date','שדה תאריך')" class="element-btn">שדה תאריך</button>
      <button @click="handle_add_element('el-input','email','שדה אימייל')" class="element-btn">שדה אימייל</button>
      <button @click="handle_add_element('el-input','tel','שדה טלפון')" class="element-btn">שדה טלפון</button>
      <button @click="handle_add_element('el-button','primary')" class="element-btn">כפתור</button>
      <button @click="handle_add_element('el-select','select','שדה בחירה יחיד')" class="element-btn">שדה בחירה יחיד</button>
      <button @click="handle_add_element('el-select','multi_select','שדה בחירה מרובה')" class="element-btn">שדה בחירה מרובה</button>
      <button @click="handle_add_element('SignaturePad','signature','שדה חתימה')" class="element-btn">שדה חתימה</button>
  </div>
</template>

<script>
import {handle_add_element} from '../../../../../../Scripts/Forms_builder'
export default {
    setup(){
        
       


        return{handle_add_element}
    }
}
</script>

<style scoped>
    .elements{
        padding: 5px;
        width: 100%;
        height: 100%;
        display: grid;
        grid-template-columns: repeat(2,1fr);
        grid-gap: 5px;
        grid-auto-rows: 70px;
    }
    .element-btn{
        border: 0;
        border-radius: 10px;
        background: #283046;
        color: #fff;
        font-size: 20px;
        cursor: pointer;
        padding: 5px;
    }
    
</style>