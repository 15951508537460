<template>
  <div class="form-manage">
      <table>
          <tr>
              <th style="cursor:pointer;" @click="handleSortTable('נוצר בתאריך', !selectedSorted.order)">נוצר בתאריך</th>
              <th style="cursor:pointer;"  @click="handleSortTable('שם הטופס', !selectedSorted.order)">שם הטופס</th>
              <th>פעולות</th>
          </tr>
          <template v-for="form in sorted_records" :key="form.form_uid">
             <tr>
                <td>{{new Date(form.created_at.seconds*1000).toLocaleDateString('he')}}</td>
                <td>{{form.form_name}}</td>
                <td>
                    <i @click="handle_edit(form)" class="material-icons edit-icon">edit</i>
                    <i @click="handle_delete_form(form)" style="margin-right:15px;" class="material-icons delete-icon">delete</i>
                </td>
             </tr>
          </template>
      </table>
  </div>
</template>

<script>
import { ref } from '@vue/reactivity'
import {get_all_build_form_from_db,builded_forms,
handle_edit_form,handle_delete_form,search} from '../../../../../Scripts/Forms_builder'
import { computed } from '@vue/runtime-core'
export default {
    emits:['edit'],
    setup(_,{emit}){

        const selectedSorted = ref({
            name: "",
            order: false
        });

        const handleSortTable = (name, order) => {
            selectedSorted.value.name = name;
            selectedSorted.value.order = order;
        };
        
        const sorted_records = ref(computed(()=>{
            if (selectedSorted.value.name == "") return filter_by_search.value
            if (selectedSorted.value.name == "נוצר בתאריך") {
                return filter_by_search.value.sort((a, b) => {
                    if (selectedSorted.value.order) {
                        return  new Date(a.created_at.seconds * 1000) > new Date(b.created_at.seconds * 1000)
                            ? 1
                            : new Date(b.created_at.seconds * 1000)  > new Date(a.created_at.seconds * 1000) 
                            ? -1
                            : 0;
                    }
                    return new Date(a.created_at.seconds * 1000)  > new Date(b.created_at.seconds * 1000) 
                        ? -1
                        : new Date(b.created_at.seconds * 1000)  > new Date(a.created_at.seconds * 1000) 
                        ? 1
                        : 0;
                })
            }
            if (selectedSorted.value.name == "שם הטופס") {
                return filter_by_search.value.sort((a, b) => {
                    if (selectedSorted.value.order) {
                        return  a.form_name > b.form_name
                            ? 1
                            : b.form_name  > a.form_name 
                            ? -1
                            : 0;
                    }
                    return a.form_name  > b.form_name
                        ? -1
                        : b.form_name  > a.form_name 
                        ? 1
                        : 0;
                })
            }
         
        }))
        const filter_by_search = ref(computed(()=>{
            if(search.value=='') return builded_forms.value
            return builded_forms.value.filter(form=>{
                if(new Date(form.created_at.seconds*1000).toLocaleDateString('he').includes(search.value)) return form
                if(form.form_name.includes(search.value)) return form 
            })
        }))
        const handle_edit = (form)=>{
            handle_edit_form(form)
            emit('edit')
        }
        get_all_build_form_from_db()
        return{
            get_all_build_form_from_db,
            handle_edit_form,
            builded_forms,
            handle_edit,
            handle_delete_form,
            filter_by_search,
            selectedSorted,
            handleSortTable,
            sorted_records
        }
    }
}
</script>

<style scoped>
    .form-manage{
        width: 100%;
        height: 100%;
        background: #fff;
        overflow:hidden;
        overflow-y: auto;
    }
    table {
        border-collapse: collapse;
        width: 100%;
    }
    table td, table th {
        border: 1px solid #ddd;
        padding: 2px;
       
    }
    table tr:nth-child(even){background-color: #f2f2f2;}
    table tr:nth-child(odd){background-color: #e7e6e6;}
    table tr:hover {background-color: #ddd;}
    table td{
        text-align: center;
        color: var(--main);
        font-size: 16px;

    }
    table th {
        padding-top: 4px;
        padding-bottom: 4px;
        text-align: center;
        background-color: var(--secondary);
        color: white;
        position: sticky;
        top: 0;
    }
    .edit-icon{
        cursor: pointer;
        color: #f5953b;
    }
    .delete-icon{
        cursor: pointer;
        color: crimson;
    }
</style>