<template>
  <div class="tfasim">
      <div class="tfasim-header">
          <button @click="handle_new_form" class="new-btn">טופס חדש</button>
          <el-input v-model="search" style="width:30%; margin-right:5px;" placeholder="חיפוש" />
      </div>
      <div class="tfasim-content">
          <div v-if="state!='ManageForm'" @click="state='ManageForm'" class="back-btn"><i class="material-icons">arrow_back</i></div>
          <component @edit="state='NewForm'" :is="state"/>
      </div>
  </div>
</template>

<script>
import { ref } from '@vue/reactivity'
import ManageForm from '../Forms/Components/ManageForm.vue'
import NewForm from '../Forms/Components/NewForm.vue'
import {current_element,elements,edit_form,search} from '../../../../Scripts/Forms_builder'
export default {
    components:{ManageForm,NewForm},
    setup(){

      const handle_new_form = ()=>{
          edit_form.value = null
          elements.value = []
          current_element.value = null
          state.value = 'NewForm'
      }

      const state = ref('ManageForm')

      return{
        state,
        handle_new_form,
        search
      }
    }
}
</script>

<style scoped>
    .tfasim{
        width: 100%;
        height: 100%;
        position: relative;
    }
    .tfasim-header{
        width: 100%;
        height: 50px;
        display: flex;
        align-items: center;
    }
    .tfasim-content{
        position: relative;
        width: 100%;
        height: calc(100% - 50px);
    }
    .option-btn,.new-btn{
        width: 100px;
        height: 80%;
        border: 0;
        border-radius: 5px;
        background: #28c76f;
        color: #fff;
        cursor: pointer;
        font-size: 18px;
    }
    .back-btn{
        position: absolute;
        width: 25px;
        height: 25px;
        top: 5px;
        left: 5px;
        background: crimson;
        color: #fff;
        cursor: pointer;
        display: flex;
        flex-direction: column;
        justify-content: center;
        align-items: center;
        border-radius: 50%;
        z-index: 12;
    }
    
</style>