<template>
  <div class="style-element">
      <StyleInputs v-if="current_element && (current_element.el=='el-input' || current_element.type=='select' || current_element.type=='multi_select')" />
      <StyleButtons v-if="current_element && current_element.el=='el-button'"/>
      <StyleSignature v-if="current_element && current_element.el=='SignaturePad'"/>
      <StyleHeader v-if="current_element && current_element.el=='h1'"/>
      <StyleParagraph v-if="current_element && current_element.el=='p'"/>
  </div>
</template>

<script>
import {current_element} from '../../../../../../Scripts/Forms_builder'
import StyleInputs from './StyleElements/StyleInputs.vue'
import StyleButtons from './StyleElements/StyleButtons.vue'
import StyleSignature from './StyleElements/StyleSignature.vue'
import StyleHeader from './StyleElements/StyleHeader.vue'
import StyleParagraph from './StyleElements/StyleParagraph.vue'


export default {
    components:{StyleInputs,StyleButtons,StyleSignature,StyleHeader,StyleParagraph},
    setup(){

        return{current_element}
    }
}
</script>

<style scoped>
    .style-element{
        width: 100%;
        height: 100%;
    }
</style>