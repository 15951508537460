<template>
  <div class="style-paragraph">
      <div class="style-paragraph-field">
          <h2>תוכן הפסקה</h2>
            <el-input
                v-model="current_element.content"
                :rows="8"
                type="textarea"
                placeholder="פה כותבים את הפסקה..."
            />
      </div>
      <div class="style-paragraph-field">
          <h2>מיקום פיסקה</h2>
          <div class="headers_options">
               <el-button @click="current_element.p_position='right'" type="primary">ימין</el-button>
               <el-button @click="current_element.p_position='center'" type="primary">מרכז</el-button>
               <el-button @click="current_element.p_position='left'" type="primary">שמאל</el-button>
          </div>
      </div>
      <div class="style-paragraph-field">
          <h2>עיצוב פסקה</h2>
          <div class="headers_options">
               <el-button size="small" @click="current_element.p_bold=='unset'?current_element.p_bold='bold':current_element.p_bold='unset'" round type="danger">
                   <i class="material-icons">format_bold</i>
               </el-button>
               <el-button size="small" @click="current_element.p_decoration=='none'?current_element.p_decoration='underline':current_element.p_decoration='none'" round type="success">
                    <i class="material-icons">format_underlined</i>
               </el-button>
               <el-button size="small" @click="current_element.p_style=='normal'?current_element.p_style='italic':current_element.p_style='normal'" round type="warning">
                   <i class="material-icons">format_italic</i>
               </el-button>
          </div>
      </div>
      <div class="style-paragraph-field-rows">
          <div class="col-2">
            <h2>גודל טקסט</h2>
            <el-input-number style="width:100%" v-model="current_element.p_size" :min="1" :max="100" />
          </div>
          <div class="col-2">
            <h2>צבע טקסט</h2>
            <el-input type="color" v-model="current_element.p_color" />
          </div>
      </div>
      <div class="style-paragraph-field-rows">
          <div class="col-2">
            <h2>שוליים צדדיים</h2>
            <el-input-number style="width:100%" v-model="current_element.p_padding_sides" :min="0" :max="100" />
          </div>
          <div class="col-2">
            <h2>שוליים עליונים</h2>
            <el-input-number style="width:100%" v-model="current_element.p_padding_u_d" :min="0" :max="100" />
          </div>
      </div>
   
     
     
     
     
       
  </div>
</template>

<script>
import {current_element} from '../../../../../../../Scripts/Forms_builder'
export default {
    setup(){

        return{current_element}
    }
}
</script>

<style scoped>
    .style-paragraph{
        width: 100%;
        height: 100%;
        padding: 5px;
    }
    .style-paragraph-field{
        margin-bottom: 5px;
        width: 100%;
        height: fit-content;
    }
    .style-paragraph-field-rows{
        margin-bottom: 5px;
        width: 100%;
        height: fit-content;
        display: flex;
        justify-content: space-between;
    }
    .col-3{
        width: 30%;
        height: fit-content;
    }
    .col-2{
        width: 49%;
        height: fit-content;
    }
    .headers_options{
        width: 100%;
        height: fit-content;
        display: flex;
        justify-content: space-between;
    }
</style>