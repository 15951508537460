<template>
  <div class="new-position">
      <div class="new-position-field">
          <h2>שם התפקיד <span style="color:red;">*</span></h2>
          <el-input v-model="values.name"  placeholder="שם המשרה" />
      </div>
      <div class="new-position-field">
          <h2>תיאור התפקיד <span style="color:red;">*</span></h2>
           <el-input
                v-model="values.description"
                :rows="2"
                type="textarea"
                placeholder="תיאור המשרה..."
            />
      </div>
      <div class="new-position-field">
          <h2>מכסת משרות</h2>
          <el-input-number style="width:100%;" v-model="values.max_apllications" :min="1" :max="1000" />
      </div>
      <div class="new-position-field">
        <h2>משרה פעילה?</h2>
        <el-select style="width:100%;" v-model="values.active" class="m-2" placeholder="Select" size="large">
            <el-option
            v-for="status in active_options"
            :key="status.value"
            :label="status.label"
            :value="status.value"
            />
        </el-select>
      </div>
      <div class="new-position-field">
          <h2>בחירת טפסים</h2>
            <el-select
                style="width:100%;"
                v-model="selected_forms"
                multiple
                filterable
                default-first-option
                :reserve-keyword="false"
                placeholder="בחירת טפסים"
                >
                <el-option
                v-for="form in build_forms_options"
                :key="form.uid"
                :label="form.form_name"
                :value="form.uid"
                />
            </el-select>
            <table v-if="values.forms.length>0">
                <tr>
                    <th>שם הטופס</th>
                    <th>סדר הצגה</th>
                    <th></th>
                    <th></th>
                </tr>
                <template v-for="form in values.forms" :key="form">
                   <tr>
                       <td>{{form.form_name}}</td>
                       <td>{{form.order}}</td>
                       <td><i style="cursor:pointer;" @click="handle_changle_order_down(form)" class="material-icons">arrow_downward</i></td>
                       <td><i style="cursor:pointer;" @click="handle_changle_order_up(form)"  class="material-icons">arrow_upward</i></td>
                   </tr>     
                </template>
            </table>
      </div>
      <div class="new-position-field">
        <el-button v-if="!pos_edit" @click="handle_submit" style="width:100%; background:#6610f2; border:0; color:#fff; font-size:18px;">שליחה</el-button>
        <el-button v-if="pos_edit" @click="handle_update" style="width:100%; background:#6610f2; border:0; color:#fff; font-size:18px;">עדכון</el-button>
      </div>
  </div>
</template>

<script>
import { ref } from '@vue/reactivity'
import {save_position_data_in_db,update_position_data_in_db} from '../../../Scripts/Positions'
import {get_build_form_by_name_and_uid_from_db} from '../../../Scripts/Forms_builder'
import {alert} from '../../../Scripts/Msgs'
import { watch } from '@vue/runtime-core'
export default {
    props:['pos_edit'],
    emits:['after_edit','new_pos'],
    setup(props,{emit}){
        const build_forms_options = ref([])
        const selected_forms = ref([])

        const error_msg = ref('')
        const active_options = ref([
            {
                label:'פעילה',
                value:true
            },
            {
                label:'לא פעילה',
                value:false
            },
        ])
        
        const values = ref({
            created_at:new Date(),
            name:'',
            description:'',
            requests:[],
            active:true,
            forms:[],
            max_apllications:100,
        })

        const handle_changle_order_down = (form)=>{
                
           const index = values.value.forms.findIndex(_form=>_form.form_uid==form.form_uid)

           if(index!=-1 && index < values.value.forms.length-1){
               const temp = values.value.forms[index+1]
               const current = values.value.forms[index]
               current.order = current.order+1
               temp.order = temp.order-1
               values.value.forms[index+1] = current
               values.value.forms[index] = temp
           }
        }
        const handle_changle_order_up = (form)=>{
            const index = values.value.forms.findIndex(_form=>_form.form_uid==form.form_uid)
           if(index!=-1 && index > 0){
               const temp = values.value.forms[index-1]
               const current = values.value.forms[index]
               current.order = current.order-1
               temp.order = temp.order+1
               values.value.forms[index-1] = current
               values.value.forms[index] = temp
           }
        }

        const validation = ()=>{
            if(!values.value.name){
                error_msg.value = 'עליך להזין שם תפקיד'
                return false
            }
            if(!values.value.description){
                error_msg.value = 'עליך להזין תיאור תפקיד'
                return false
            }
            return true
        }
        const handle_submit = async()=>{
            if(!validation()){
                alert('error','שגיאה',error_msg.value)
            }else{
                await save_position_data_in_db(values.value)
                alert('success','הצלחה'
                ,`התפקיד ${values.value.name} נקלט בהצלחה`)
                .then(()=>{
                    reset()
                    emit('new_pos')
                })
            }
        }

        const handle_update = async()=>{
            if(!validation()){
                alert('error','שגיאה',error_msg.value)
            }else{
                await update_position_data_in_db(values.value)
                alert('success','הצלחה'
                ,`התפקיד ${values.value.name} עודכן בהצלחה`)
                .then(()=>{
                    emit('after_edit',values.value)
                })
            }
        }

        const reset = ()=>{
            values.value = {
                created_at:new Date(),
                name:'',
                description:'',
                requests:[],
                active:true,
                forms:[],
                max_apllications:100,
            }
        }

        const init = async()=>{ 
            if(props.pos_edit){
                values.value = {...props.pos_edit}
                selected_forms.value = values.value.forms.map(form=>form.form_uid)
            }
            build_forms_options.value = await get_build_form_by_name_and_uid_from_db()
        }

       init()

       watch(selected_forms,()=>{
           console.log(selected_forms.value);
           values.value.forms =  selected_forms.value.map((uid,i)=>{
               const index = build_forms_options.value.findIndex(form=>form.uid==uid)
               if(index!=-1){
                   return {
                       form_name:build_forms_options.value[index].form_name,
                       form_uid:build_forms_options.value[index].uid,
                       order:i
                   }
               }
           })
       })

        return{
            handle_submit,
            validation,
            handle_update,
            handle_changle_order_down,
            handle_changle_order_up,
            build_forms_options,
            values,
            active_options,
            error_msg,
            selected_forms
        }
    }
}
</script>

<style scoped>
    .new-position{
        margin: 0 auto;
        width: 100%;
        max-width: 500px;
        height: 100%;
        color: #fff;
    }
    .new-position-field{
        margin-bottom: 5px;
        width: 100%;
        height: fit-content;
    }
    table {
        border-collapse: collapse;
        width: 100%;
    }
    table td, table th {
        border: 1px solid #ddd;
        padding: 2px;
       
    }
    table tr:nth-child(even){background-color: #f2f2f2;}
    table tr:nth-child(odd){background-color: #e7e6e6;}
    table tr:hover {background-color: #ddd;}
    table td{
        text-align: center;
        color: var(--main);
        font-size: 16px;

    }
    table th {
        padding-top: 4px;
        padding-bottom: 4px;
        text-align: center;
        background-color: var(--secondary);
        color: white;
        position: sticky;
        top: 0;
    }
</style>